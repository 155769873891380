<template>
  <div class="body-custom" v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">アカウント検索</b></h3>
        </div>
        <div>
          <ButtonSearchMobile></ButtonSearchMobile>
        </div>
      </div>
      <div class="card-body">
        <div id="search-form">
          <el-form
              ref="ruleForm"
              label-position="left"
              class="demo-ruleForm demo-form"
              @keyup.enter.native="onSearch"
          >
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-6">
                <div class="row">
                  <div class="col-md-3 col-6">
                    <el-form-item label="メールアドレス" prop="mail">
                      <el-input
                      name="email"
                      autocomplete="on"
                        type="text"
                          v-model="searchForm.mail"
                          placeholder="メールアドレスを入力"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-3 col-6">
                    <el-form-item label="ステータス" prop="user_status">
                      <el-select
                          multiple
                          style="width: 100%"
                          v-model="searchForm.user_status"
                          placeholder="選択してください"
                      >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
<!--                    phase 2-->
                    <div class="col-md-3 col-6">
                        <el-form-item label="権限名" prop="role_id">
                            <el-select multiple style="width: 100%" v-model="searchForm.role_id" placeholder="選択してください">
                                <el-option
                                    v-for="item in listRoleName"
                                    :key="item.role_id"
                                    :label="item.role_name"
                                    :value="item.role_id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div  v-if="currentUser.super_user" class="col-md-3 col-6">
                        <el-form-item  label="テナント">
                            <el-select  style="width: 100%" v-model="searchForm.tenant_code" placeholder="選択してください">
                                <el-option
                                    label="なし"
                                    value=""> </el-option>
                                <el-option
                                    v-for="(item, index) in allTenant"
                                    :key="index"
                                    :label="item.tenant_name"
                                    :value="item.tenant_code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>

<!--                    phase 2-->
                  <div class="col-12 text-right">
                    <div class="button-submit-mobile" style="padding-top: 0">
                      <el-button
                          @click="showFormSearch()"
                          @click.prevent="onSearch"
                          type="primary"
                      >{{ $t("search") }}</el-button
                      >
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-4 col-xs-6 d-flex flex-wrap">
                  <div class="el-form-item mr-3">
                      <label class="el-form-item__label">&nbsp; </label>
                      <div class="el-form-item__content">
                          <div class="el-input">
                              <el-button @click.prevent="onSearch" type="primary">{{ $t("search") }}</el-button>
                          </div>
                      </div>
                  </div>
                  <div class="el-form-item">
                      <label class="el-form-item__label">&nbsp; </label>
                      <div class="el-form-item__content">
                          <div class="el-input">
                              <el-button :disabled="listAccount.total <= 0" @click.prevent="exportExcel" type="primary">{{ $t("export_csv") }}</el-button>
                          </div>
                      </div>
                  </div>

              </div>
            </div>
          </el-form>
        </div>
        <div id="data-table-list">
          <div class="header-table-mobile">
            <el-button
                :disabled="listAccount.total <= 0"
                @click.prevent="exportExcel"
                type="primary"
            >{{ $t("export_csv") }}</el-button>
          </div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listAccount.total"
                :total-record="parseInt(listAccount.total)"
                :current-page="parseInt(listAccount.current_page)"
                :page-size-default="parseInt(listAccount.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
          <el-table
              v-if="listAccount.data && listAccount.total > 0"
              class="mb-5 table-search"
              :data="listAccount.data"
              style="width: 100%"
              @sort-change="sortAccount"
          >
            <el-table-column
                prop="mail"
                class="column-table-2"
                label="メールアドレス"
                sortable="false"
                min-width="150"
            >
            <template slot-scope="scope">
                <span v-if="hasPermissonCheck('kengen_flg')">
                    <router-link :to="{ name: 'edit-account', params: { user_id:  scope.row.user_id }}">
                        {{ scope.row.mail}}
                    </router-link>
                 </span>
                <span v-else>
                    <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.mail}}</a>
                 </span>
              </template>
            </el-table-column>
<!--              phase 2-->
              <el-table-column
                prop="mail"
                class="column-table-2"
                label="権限名"
                sortable="false"
                min-width="130">
            <template slot-scope="scope">
                <span  v-if="currentUser.super_user || currentUser.tenant_code == scope.row.role.tenant_code">
                  <router-link :to="{ name: 'edit-role', params: { role_id: scope.row.role_id}}">
                     {{ scope.row.role ? scope.row.role.role_name : ''}}
                  </router-link>
                 </span>
                <span v-else>
                  {{ scope.row.role ? scope.row.role.role_name : ''}}
                </span>
              </template>
            </el-table-column>
<!--              phase 2-->
            <el-table-column
                prop="user_status"
                class="column-table"
                label="ステータス"
                sortable="false"
                min-width="130"
            >
              <template slot-scope="scope">
                <span v-if="parseInt(scope.row.user_status) === constants.USER_STATUS[0].value">{{constants.USER_STATUS[0].label}}</span>
                <span v-else-if="parseInt(scope.row.user_status) === constants.USER_STATUS[1].value"
                >{{constants.USER_STATUS[1].label}}</span
                >
              </template>
            </el-table-column>
              <el-table-column
                  prop="user_status"
                  class="column-table"
                  label="テナント名"
                  min-width="130"
              >
                  <template slot-scope="scope">
                      <span v-if="scope.row.tenant">
                        <a target="_blank" :href="scope.row.tenant.admin_url">
                          {{scope.row.tenant.tenant_name}}
                        </a>
                      </span>
                  </template>
              </el-table-column>

          </el-table>
          <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listAccount.total"
                :total-record="parseInt(listAccount.total)"
                :current-page="parseInt(listAccount.current_page)"
                :page-size-default="parseInt(listAccount.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import mixinSearch from "@/mixins/searchMixin";
import { mapActions, mapGetters } from "vuex";
import {
    GET_LIST_ROLE_NAME,
} from "@/core/services/store/approval.module";
import {
  GET_LIST_ACCOUNT,
  EXPORT_LIST_ACCOUNT_CSV,
} from "@/core/services/store/account.module";
import * as constants from "@/core/config/constant";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";

export default {
  components: {ButtonSearchMobile},
  mixins: [mixinSearch],
  data() {
    return {
      searchForm: {
        mail: "",
        user_status: [],
        role_id:[],
        tenant_code: ''
      },
      options: [],
      loading: false,
      constants: constants,
        not_permisson_popup:false
    };
  },
  computed: {
    ...mapGetters(["listAccount", "listRoleName","allTenant","currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },
  watch: {
    async queryParam() {
      this.loading = true;
        if (Object.keys(this.queryParam).length === 0){
            this.resetDataSearch();
        }
      await this.getListAccount(this.queryParam);
      this.getDataFromQuery();
      this.loading = false;
    },
  },
  async created() {
    if (this.hasPermissonCheck('kengen_flg')) {
      this.options = constants.USER_STATUS;
      this.loading = true;
      this.getDataFromQuery();
      await this.getListAccount(this.queryParam);
      await this.getListRoleName();
      await this.getAllTenant();
      this.loading = false;
    } else {
      this.not_permisson_popup = true
    }
  },
  methods: {
    ...mapActions([GET_LIST_ACCOUNT, EXPORT_LIST_ACCOUNT_CSV,GET_LIST_ROLE_NAME,GET_ALL_TENANT]),
    getDataFromQuery() {
      var self = this
      this.searchForm.mail = this.queryParam.mail ? this.queryParam.mail : "";
      var arrayStatus = (this.queryParam.user_status && this.queryParam.user_status.length) ? this.queryParam.user_status.split(',') : [];
      this.searchForm.user_status = arrayStatus.map(item => parseInt(item));

      let arrayRole = (this.queryParam.role_id && this.queryParam.role_id.length ) ? this.queryParam.role_id.split(',') : [];
      this.searchForm.role_id = arrayRole.map(item => parseInt(item));
      this.searchForm.tenant_code = this.queryParam.tenant_code ? this.queryParam.tenant_code : '';

      // if(typeof this.queryParam.role_id == 'string'){
      //   var role = this.queryParam.role_id.toString();
      //   var arrayRole = this.queryParam.role_id ? role.split(',') : [];
      //   this.searchForm.role_id = arrayRole.map(item => parseInt(item));
      // }
      // else{
      //   this.searchForm.role_id.push(this.queryParam.role_id)
      // }
    },
    onSearch() {
      this.searchForm.mail = this.searchForm.mail.trim()
      this.getDataSearch(this.searchForm);
      this.search("list-account");
    },
    sortAccount(sortProps) {
      this.sortColumn("list-account",sortProps);
      this.search("list-account");
    },
    handleSizeChange(val) {
      this.sizeChange("list-account", val);
    },
    handleCurrentChange(val) {
      this.pageChange("list-account", val);
    },
    async exportExcel() {
        if (this.hasPermissonCheck('kengen_flg')){
            let data = await this.exportListAccountFileCsv(this.queryParam);
            this.exportCsvCommon('SC008_',data);
        }else {
            this.not_permisson_popup = true
        }
    },
  },
};
</script>
<style scoped>
.ml-10 {
  margin-left: 10rem !important;
}

</style>
